import React from "react";
import Success from "../assets/success.svg";
import Loader from "../assets/Frame 1000003082.png";

const Screen2 = ({simSelect, simCount, environment}) => {
  return (
    <>
      <div className="stepperContainer">
        <div className="leftpart">
          <img className="success" src={Success} alt="loader" />
          <span className="verticalLine"></span>
        </div>
        <div className="rightpart">
          <span className="child1">SMS has been triggered!</span>
          {simCount === 1 || environment === "react-native-ios"? (
            <span className="child2">
              An SMS has been triggered!
            </span>
          ) : (
            <span className="child2">
              An SMS has been triggered from <span>{simSelect}</span>
            </span>
          )}
        </div>
      </div>

      <div className="stepperContainer">
        <div className="leftpart">
          <img className="loader" src={Loader} alt="Loader" />
        </div>
        <div className="rightpart">
          <span className="child1">Waiting for server to receive message. Check for SMS balance</span>
        </div>
      </div>
    </>
  );
};

export default Screen2;
