import { useEffect, useState } from "react";
import axios from "axios";
import flag from "../india-flag-icon.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../css/login.css";
import mainLogo from "../logo.png";
import { cognito } from "../utils/aws";
import { Link, useSearchParams } from "react-router-dom";
import SuccessAnim from "../assets/success.gif";
import Success from "../assets/suuccess1.svg";
import Failure from "../assets/failure.svg";

import { forgotPasswordSchema, otpSchemaForgotPassword } from "../schemas";
import { useFormik } from "formik";
import { ColorRing } from "react-loader-spinner";
import { getAppName } from "../utils/accessControl";

const ForgotPassword = () => {
  const [phone, setPhone] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);
  const [timeoutId, setTimeoutId] = useState("");
  const [validations, setValidations] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const [searchParams] = useSearchParams();

  const forgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("forgotPassword called");
    setErrorMessage("");
    try {
      await axios
        .post(`${process.env.REACT_APP_OTP_URL}/verify-otp`, {
          request: "true",
          username: `+91${phone}`,
          type: "forgot",
          appName: getAppName(searchParams.get("redirect_uri")),
        })
        .then(() => {
          setStep(2);
          console.log("Step 2 set done");
          setLoading(false);
        })
        .catch((err) => {
          console.log("Error:", err);
          console.log("Error:", err?.response?.status);
          if (err?.response?.status === 404) {
            setErrorMessage(
              "User is not signed up yet. Please Sign up to access the application."
            );
          } else {
            setErrorMessage(err?.response?.data?.message);
          }

          setLoading(false);
        });
    } catch (error) {
      console.log("Error 2: ", error);
      setErrorMessage(error?.message);
    }
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    setMinutes(0);
    setSeconds(59);
    // setLoading(true);
    console.log("forgotPassword called");
    setErrorMessage("");
    try {
      await axios
        .post(`${process.env.REACT_APP_OTP_URL}/verify-otp`, {
          request: "true",
          username: `+91${phone}`,
          type: "forgot",
          appName: getAppName(searchParams.get("redirect_uri")),
        })
        .catch((err) => {
          console.log("Error:", err);
          setErrorMessage(err?.response?.data?.message);
        });
    } catch (error) {
      setErrorMessage(error?.message);
    }
  };

  const confirmForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    setErrorMessage("");
    try {
      await axios.post(`${process.env.REACT_APP_OTP_URL}/verify-otp`, {
        verify: "true",
        otp: confirmCode,
        username: `+91${phone}`,
        password: newPassword,
        type: "forgot",
      });

      setStep(3);
      const id = setTimeout(() => {
        window.location.href =
          process.env.REACT_APP_AUTH_URL +
          `/?client_id=${searchParams.get(
            "client_id"
          )}&redirect_uri=${searchParams.get("redirect_uri")}`;
      }, 10000);
      setTimeoutId(id);
    } catch (err) {
      console.log("Error:", err);

      if (err.response && err.response.data && err.response.data.message) {
        setErrorMessage(err.response.data.message);
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setErrorMessage("");
  }, [phone, newPassword, confirmPassword, confirmCode]);

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: forgotPasswordSchema,
    validateOnMount: true,
  });

  const formikOTPPassword = useFormik({
    initialValues: {
      confirmCode: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: otpSchemaForgotPassword,
    validateOnMount: true,
  });

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    formikOTPPassword.setFieldValue("password", e.target.value);
    formik.setFieldValue("password", e.target.value);
    const newPassword = e.target.value;
    // Check for length
    const hasValidLength = newPassword.length >= 8;
    // Check for lowercase
    const lowercaseRegex = /[a-z]/;
    const hasLowercase = lowercaseRegex.test(newPassword);
    // Check for uppercase
    const uppercaseRegex = /[A-Z]/;
    const hasUppercase = uppercaseRegex.test(newPassword);
    // Check for number
    const numberRegex = /[0-9]/;
    const hasNumber = numberRegex.test(newPassword);
    // Check for special character
    const specialCharRegex = /[!@#$%^&*]/;
    const hasSpecialChar = specialCharRegex.test(newPassword);
    setValidations({
      length: hasValidLength,
      lowercase: hasLowercase,
      uppercase: hasUppercase,
      number: hasNumber,
      specialChar: hasSpecialChar,
    });
  };
  const renderValidationMessage = (isValid, message) => {
    return isValid ? (
      <p style={{ color: "green" }}>
        <img
          src={Success}
          alt="loader"
          style={{ width: "1rem", color: "green" }}
        />{" "}
        {message}
      </p>
    ) : (
      <p style={{ color: "red" }}>
        <img src={Failure} alt="loader" style={{ width: "1rem" }} /> {message}
      </p>
    );
  };

  return (
    <div className="container">
      <div className="forms-container">
        <div className="signin-signup">
          {step === 1 && (
            <form>
              <div className="custom_form">
                <img src={mainLogo} alt="Logo" className="logo" />
                <br />
                <h2 className="title">Forgot Password</h2>
                <div className="input-field required">
                  <i className="fa fa-phone" aria-hidden="true"></i>

                  <span className="contryCode">
                    <img src={flag} alt="Logo" className="flag" /> +91{" "}
                  </span>
                  <input
                    maxLength={10}
                    type="text"
                    placeholder="Mobile Number"
                    onChange={(e) => {
                      setPhone(e.target.value);
                      formik.setFieldValue("mobile", e.target.value);
                    }}
                    value={phone}
                    onBlur={formik.handleBlur}
                    onKeyPress={(e) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                        e.preventDefault();
                      }
                    }}
                    name="mobile"
                    id="mobile"
                    className={
                      formik.touched.mobile && formik.errors.mobile
                        ? "error"
                        : ""
                    }
                  />
                </div>
                {formik.touched.mobile && formik.errors.mobile && (
                  <div className="error-message">{formik.errors.mobile}</div>
                )}

                {errorMessage && (
                  <>
                    <p className="error-message">{errorMessage}</p>
                  </>
                )}

                {isLoading === true ? (
                  <div>
                    <center>
                      <p>"OTP sent please wait..!"</p>
                      <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["#3347c5", "#331492", "#ff0000"]}
                      />
                    </center>
                  </div>
                ) : (
                  <button
                    onClick={forgotPassword}
                    className={formik.isValid ? "btn solid " : "loginButton"}
                    disabled={!formik.isValid}
                  >
                    Get OTP
                  </button>
                )}

                <Link
                  to={`/?client_id=${searchParams.get(
                    "client_id"
                  )}&redirect_uri=${searchParams.get("redirect_uri")}`}
                >
                  Sign In
                </Link>
              </div>
            </form>
          )}

          {step === 2 && (
            <div>
              <form onSubmit={confirmForgotPassword}>
                <h2>Confirm Password Reset</h2>
                <p>OTP sent to your mobile number:</p>
                <div className="input-field1">
                  <i className="fa fa-lock"></i>
                  <span className="contryCode"></span>
                  <input
                    maxLength={4}
                    type="text"
                    placeholder="OTP"
                    name="confirmCode"
                    value={confirmCode}
                    // onChange={(e) => setConfirmCode(e.target.value)}
                    onBlur={formikOTPPassword.handleBlur}
                    onChange={(e) => {
                      setConfirmCode(e.target.value);
                      formikOTPPassword.setFieldValue(
                        "confirmCode",
                        e.target.value
                      );
                    }}
                    onKeyPress={(e) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {formikOTPPassword.touched.confirmCode &&
                formikOTPPassword.errors.confirmCode ? (
                  <div className="error-message">
                    {formikOTPPassword.errors.confirmCode}
                  </div>
                ) : null}
                <div className="input-field1">
                  <i className="fa fa-key" aria-hidden="true"></i>
                  <span className="contryCode"></span>
                  <input
                    placeholder="Enter New Password"
                    type={passwordVisible ? "text" : "password"}
                    // value={formik.values.password}
                    onChange={handlePasswordChange}
                    onBlur={formikOTPPassword.handleBlur}
                    name="password"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    aria-label={
                      passwordVisible ? "Hide password" : "Show password"
                    }
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      height: "100%",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      // padding: "0 1rem",
                      paddingRight: "1rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    {/* Or use Unicode characters: */}
                    {/* {passwordVisible ? '🙈' : '👁'} */}
                  </button>
                </div>
                {formikOTPPassword.touched.password &&
                formikOTPPassword.errors.password ? (
                  <div className="error-message">
                    {formikOTPPassword.errors.password}
                  </div>
                ) : null}
                <div className="input-field1">
                  <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                  <span className="contryCode"></span>
                  <input
                    placeholder="Confirm Password"
                    type={passwordVisible ? "text" : "password"}
                    value={formikOTPPassword.values.confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      formikOTPPassword.setFieldValue(
                        "confirmPassword",
                        e.target.value
                      );
                    }}
                    onBlur={formikOTPPassword.handleBlur}
                    name="confirmPassword"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    aria-label={
                      passwordVisible ? "Hide password" : "Show password"
                    }
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      height: "100%",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      // padding: "0 1rem",
                      paddingRight: "1rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    {/* Or use Unicode characters: */}
                    {/* {passwordVisible ? '🙈' : '👁'} */}
                  </button>
                </div>

                {formikOTPPassword.touched.confirmPassword &&
                formikOTPPassword.errors.confirmPassword ? (
                  <div className="error-message">
                    {formikOTPPassword.errors.confirmPassword}
                  </div>
                ) : null}

                {/* {newPassword.length > 0 && newPassword === confirmPassword ? (
                  <span style={{ color: "green" }}>Password matched ..!</span>
                ) : null} */}

                <div>
                  <h3>Password must contain: </h3>
                  {renderValidationMessage(
                    validations.length,
                    "At least 8 characters"
                  )}
                  {renderValidationMessage(
                    validations.lowercase,
                    "Lowercase letters (a-z)"
                  )}
                  {renderValidationMessage(
                    validations.uppercase,
                    "Uppercase letters (A-Z)"
                  )}
                  {renderValidationMessage(validations.number, "Numbers (0-9)")}
                  {renderValidationMessage(
                    validations.specialChar,
                    "At least 1 special character(e.g. !@#$%^&*)"
                  )}
                </div>

                {isLoading === true ? (
                  <div>
                    <center>
                      <p>Resetting password please wait..!</p>
                      <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["#3347c5", "#331492", "#ff0000"]}
                      />
                    </center>
                  </div>
                ) : (
                  <button
                    onClick={confirmForgotPassword}
                    className={
                      formikOTPPassword.isValid ? "btn solid " : "loginButton"
                    }
                    disabled={!formikOTPPassword.isValid}
                  >
                    Reset Password
                  </button>
                )}

                {seconds > 0 || minutes > 0 ? (
                  <p>
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>Didn't recieve code?</p>
                )}
                <button
                  disabled={seconds > 0 || minutes > 0}
                  style={{
                    color:
                      seconds > 0 || minutes > 0 ? "#DFE3E8" : "rgb(0, 0, 205)",
                  }}
                  onClick={resendOTP}
                  className="link-button"
                >
                  Resend OTP
                </button>

                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
              </form>
            </div>
          )}

          {step === 3 && (
            <>
              <div>
                <center>
                  <img src={SuccessAnim} alt="" />
                  <h2>!.. Password Updated ..!</h2>
                  <Link
                    to={`/?client_id=${searchParams.get(
                      "client_id"
                    )}&redirect_uri=${searchParams.get("redirect_uri")}`}
                    onClick={() => clearTimeout(timeoutId)}
                  >
                    Sign In here
                  </Link>
                </center>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
