import React from "react";
import Success from "../assets/success.svg";
import Loader from "../assets/Frame 1000003082.png";

const Screen4 = ({ simSelect, simCount, environment }) => {
  return (
    <>
      <div className="stepperContainer">
        <div className="leftpart">
          <img className="success" src={Success} alt="loader" />
          <span className="verticalLine2"></span>
        </div>
        <div className="rightpart">
          <span className="child1">SMS has been triggered!</span>
          {simCount === 1 || environment === "react-native-ios"? (
            <span className="child2">An SMS has been triggered!</span>
          ) : (
            <span className="child2">
              An SMS has been triggered from <span>{simSelect}</span>
            </span>
          )}
        </div>
      </div>

      <div className="stepperContainer">
        <div className="leftpart">
          <img className="success" src={Success} alt="Success" />
          <span className="verticalLine"></span>
        </div>
        <div className="rightpart">
          <span className="child1">SMS received!</span>

          {simCount === 1 || environment === "react-native-ios"? (
            <span className="child2">SMS has been received</span>
          ) : (
            <span className="child2">
              SMS from <span>{simSelect}</span> has been received
            </span>
          )}
        </div>
      </div>

      <div className="stepperContainer">
        <div className="leftpart">
          <img className="loader" src={Loader} alt="Loader" />
        </div>
        <div className="rightpart">
          <span className="child1">User successfully verified!</span>
          <span className="child2">Account has been verified</span>
        </div>
      </div>
    </>
  );
};

export default Screen4;
