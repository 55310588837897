import React from "react";
import "../css/blockAccess.css";
import Forbidden from "../assets/forbidden.svg";
import Logo from "../logo.png";

const NoAccess = () => {
  return (
    <div className="container1 container">
      <div className="blockModal">
        <img src={Logo} alt="Logo" style={{ width: "5rem", height: "5rem" }} />
        <br />
        <br />
        <p className="access">
          User does not have access to this Client app. <br /> Please check your
          URL and retry.
        </p>
        <img src={Forbidden} className="forbidden" alt="forbidden" />
      </div>
    </div>
  );
};

export default NoAccess;
