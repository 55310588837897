import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
// import { cognito } from "../utils/aws";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/authActions";

const cookies = new Cookies();

function Logout() {
  const [searchParams] = useSearchParams();
  // const phone_number = useSelector((state) => state.auth.user.phone_number);

  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    logout(searchParams.get("client_id"))(dispatch);
    cookies.remove("token", {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      path: "/",
    });
    cookies.remove("idToken", {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      path: "/",
    });
    cookies.remove("refreshToken", {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
      path: "/",
    });
    window.location.href =
      "/?redirect_uri=" +
      searchParams.get("redirect_uri") +
      "&client_id=" +
      searchParams.get("client_id");
  });
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      Logging out user...
    </div>
  );
}

export default Logout;
