import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import { FaPhone } from "react-icons/fa6";
import ErrorButton from "./pages/ErrorButton";
import NoAccess from "./pages/NoAccess";

function App() {
  function isStringInURL(myString) {
    const currentUrl = window.location.href;
    return currentUrl.includes(myString);
  }

  const myString = "stockist";
  const isPresent = isStringInURL(myString);

  return (
    <>
      {isPresent === true ? (
        <div
          style={{
            position: "absolute",
            right: "20px",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "5px",
          }}
        >
          <p>For Support:</p>
          <FaPhone style={{ color: "blue", marginLeft: "10px" }} />
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              marginLeft: "5px",
            }}
          >
            {" "}
            044-481-37024
          </span>
        </div>
      ) : null}
      <BrowserRouter>
        <Routes>
          <Route path="/access-blocked" element={<NoAccess />} />
          <Route path="/oauth2/authorize" element={<Login />} />
          <Route path="/Error" element={<ErrorButton />} />
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/oauth2/authorize/logout" element={<Logout />} />
          <Route path="/forgot" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
