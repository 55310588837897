const adminWebAppRoles = [
  "Management",
  "Medvol Field",
  "Company Admin",
  "Testing Team",
  "Invoice Entry",
  "Company Audit",
  "Account Manager",
  "Sr Management - HO",
  "MIS",
  "Inv Ack Verification",
  "Invoice Approval",
  "Master Module",
  "Ticketing",
  "Manual Order",
  "Call Center",
  "Call Centre",
  "MedVol Field Coordinator",
  "Company Admin - Division",
  "Company Project - HO",
];

const dashboardWebAppRoles = [
  "Management",
  "Medvol Field",
  "Company Admin",
  "Account Manager",
  "Sr Management - HO",
  "MIS",
  "MedVol Field Coordinator",
];

const opsWebAppRoles = [
  "Management",
  "Testing Team",
  "Account Manager",
  "Sr Management - HO",
  "MIS",
  "Master Module",
  "Invoice Entry",
  "Medvol Field",
  "Call Centre",
  "Ticketing",
  "MedVol Field Coordinator",
  "Manual Order",
];

const fieldWebAppRoles = ["Company Admin", "Company Admin - Division"];

const pharmacyMobileAppRoles = ["Doctor", "Hospital", "Pharmacy", "Delegate"];

const medvolFieldAppRoles = ["Medvol Field", "Management"];

export const getAppName = (redirect_uri) => {
  switch (true) {
    case redirect_uri.includes("stockist"):
      return "web:stockist";
    case redirect_uri.includes("admin"):
      return "web:admin";
    case redirect_uri.includes("dashboard") || redirect_uri.includes("report"):
      return "web:dashboard";
    case redirect_uri.includes("corpservices") || redirect_uri.includes("ops"):
      return "web:ops";
    case redirect_uri.includes("field"):
      return "web:field";
    case redirect_uri.includes("localhost") &&
      process.env.REACT_APP_ENVIRONMENT === "DEV":
      return "localhost";
    default:
      return null;
  }
};

export const checkAccess = (appName, user) => {
  switch (appName) {
    case "web:stockist":
      if (
        user["custom:mv_role"] === "Stockist" ||
        user["custom:role"] === "Stockist"
      ) {
        return true;
      } else {
        return false;
      }
    case "web:admin":
      if (
        adminWebAppRoles.includes(user["custom:mv_role"]) ||
        user["custom:emp_short_code"] ||
        adminWebAppRoles.includes(user["custom:role"])
      ) {
        return true;
      } else {
        return false;
      }
    case "web:dashboard":
      if (
        dashboardWebAppRoles.includes(user["custom:mv_role"]) ||
        user["custom:emp_short_code"] ||
        dashboardWebAppRoles.includes(user["custom:role"])
      ) {
        return true;
      } else {
        return false;
      }
    case "web:ops":
      if (
        opsWebAppRoles.includes(user["custom:mv_role"]) ||
        opsWebAppRoles.includes(user["custom:role"])
      ) {
        return true;
      } else {
        return false;
      }
    case "web:field":
      if (
        user["custom:emp_short_code"] ||
        fieldWebAppRoles.includes(user["custom:mv_role"]) ||
        fieldWebAppRoles.includes(user["custom:role"])
      ) {
        return true;
      } else {
        return false;
      }
    case "mobile:pharmacy":
      if (
        pharmacyMobileAppRoles.includes(user["custom:mv_role"]) ||
        pharmacyMobileAppRoles.includes(user["custom:role"])
      ) {
        return true;
      } else {
        return false;
      }
    case "mobile:assist":
      if (user["custom:emp_short_code"]) {
        return true;
      } else {
        return false;
      }
    case "mobile:channel":
      if (
        user["custom:mv_role"] === "Stockist" ||
        user["custom:role"] === "Stockist"
      ) {
        return true;
      } else {
        return false;
      }
    case "mobile:MedVol Field":
      if (
        medvolFieldAppRoles.includes(user["custom:mv_role"]) ||
        medvolFieldAppRoles.includes(user["custom:role"])
      ) {
        return true;
      } else {
        return false;
      }
    case "localhost":
      if (process.env.REACT_APP_ENVIRONMENT === "DEV") {
        return true;
      } else {
        return false;
      }
    default:
      return false;
  }
};
