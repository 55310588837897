import { useState, useEffect } from "react";
import axios from "axios";
import flag from "../india-flag-icon.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { cognito } from "../utils/aws";
import "../css/signup.css";
import mainLogo from "../logo.png";
import { Link, useSearchParams } from "react-router-dom";
import SuccessAnim from "../assets/success.gif";
import Success from "../assets/suuccess1.svg";
import Failure from "../assets/failure.svg";

import {
  signupSchema,
  otpSchemaSignup,
  signupPasswordSchema,
} from "../schemas";
import { useFormik } from "formik";
import { ColorRing } from "react-loader-spinner";
import { getAppName } from "../utils/accessControl";

function Register() {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);
  const [timeoutId, setTimeoutId] = useState("");
  const [validations, setValidations] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const requestOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("requestOTP call done");
    setErrorMessage("");

    try {
      await axios.post(`${process.env.REACT_APP_OTP_URL}/verify-otp`, {
        request: "true",
        username: `+91${phone}`,
        type: "signup",
        appName: getAppName(searchParams.get("redirect_uri")),
      });
      setStep(2);
      console.log("Step 2 set done");
    } catch (err) {
      console.log("Error:", err);
      if (err?.response) {
        setErrorMessage(err?.response?.data?.message);
      } else {
        setErrorMessage(
          "An unexpected error occurred. Please try again later."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    setMinutes(0);
    setSeconds(59);
    // setLoading(true);
    console.log("requestOTP call done");
    setErrorMessage("");
    await axios
      .post(`${process.env.REACT_APP_OTP_URL}/verify-otp`, {
        request: "true",
        username: `+91${phone}`,
        type: "signup",
        appName: getAppName(searchParams.get("redirect_uri")),
      })
      .then(() => {
        setStep(2);
        console.log("Step 2 set done");
      })
      .catch((err) => {
        console.log("Error:", err);
        setErrorMessage(err?.response?.data?.message);
      });
    // setLoading(false);
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("verifyOTP is  called");
    setErrorMessage("");
    await axios
      .post(`${process.env.REACT_APP_OTP_URL}/verify-otp`, {
        verify: "true",
        otp: confirmCode,
        username: `+91${phone}`,
        type: "signup",
      })
      .then(() => {
        setStep(3);
        console.log("Step 3 set done");
      })
      .catch((err) => {
        console.log("Error:", err);
        setErrorMessage(err?.response?.data?.message);
      });
    setLoading(false);
  };

  const signUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    } else {
      try {
        const data = await cognito.signup({
          phone: `+91${phone}`,
          password,
        });

        console.log(data);
        setStep(4);
        const id = setTimeout(() => {
          window.location.href =
            process.env.REACT_APP_AUTH_URL +
            `/?client_id=${searchParams.get(
              "client_id"
            )}&redirect_uri=${searchParams.get("redirect_uri")}`;
        }, 10000);
        setTimeoutId(id);
      } catch (error) {
        console.log(error);
        if (error?.message.includes("Verification Failed")) {
          setErrorMessage("Invalid User");
        } else {
          setErrorMessage(
            error?.message.replace("phone_number", "phone number")
          );
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setErrorMessage("");
  }, [phone, password, confirmPassword, confirmCode]);

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: signupSchema,
    validateOnMount: true,
  });

  const formikOTP = useFormik({
    initialValues: {
      confirmCode: "",
    },
    validationSchema: otpSchemaSignup,
    validateOnMount: true,
  });

  const formikSignupPassword = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: signupPasswordSchema,
    validateOnMount: true,
  });

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    formikSignupPassword.setFieldValue("password", e.target.value);
    const newPassword = e.target.value;
    // Check for length
    const hasValidLength = newPassword.length >= 8;
    // Check for lowercase
    const lowercaseRegex = /[a-z]/;
    const hasLowercase = lowercaseRegex.test(newPassword);
    // Check for uppercase
    const uppercaseRegex = /[A-Z]/;
    const hasUppercase = uppercaseRegex.test(newPassword);
    // Check for number
    const numberRegex = /[0-9]/;
    const hasNumber = numberRegex.test(newPassword);
    // Check for special character
    const specialCharRegex = /[!@#$%^&*]/;
    const hasSpecialChar = specialCharRegex.test(newPassword);
    setValidations({
      length: hasValidLength,
      lowercase: hasLowercase,
      uppercase: hasUppercase,
      number: hasNumber,
      specialChar: hasSpecialChar,
    });
  };
  const renderValidationMessage = (isValid, message) => {
    return isValid ? (
      <p style={{ color: "green" }}>
        <img
          src={Success}
          alt="loader"
          style={{ width: "1rem", color: "green" }}
        />{" "}
        {message}
      </p>
    ) : (
      <p style={{ color: "red" }}>
        <img src={Failure} alt="loader" style={{ width: "1rem" }} /> {message}
      </p>
    );
  };

  return (
    <div>
      <div className="container">
        <div className="forms-container">
          <div className="signin-signup">
            {step === 1 && (
              <>
                <form className="sign-up-form">
                  <img src={mainLogo} alt="Logo" className="logo" />
                  <br />
                  <h2 className="title">Sign up</h2>
                  <div className="input-field required">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    <span className="contryCode">
                      <img src={flag} alt="Logo" className="flag" /> +91{" "}
                    </span>
                    <input
                      maxLength={10}
                      type="text"
                      placeholder="Mobile Number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                        formik.setFieldValue("phone", e.target.value);
                      }}
                      value={phone}
                      onBlur={formik.handleBlur}
                      onKeyPress={(e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault();
                        }
                      }}
                      name="phone"
                      id="phone"
                      className={
                        formik.touched.phone && formik.errors.phone
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="error-message">{formik.errors.phone}</div>
                  )}

                  {isLoading === true ? (
                    <div>
                      <center>
                        <p>"OTP sent please wait..!"</p>
                        <ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={["#3347c5", "#331492", "#ff0000"]}
                        />
                      </center>
                    </div>
                  ) : (
                    <button
                      onClick={requestOTP}
                      className={formik.isValid ? "btn solid " : "loginButton"}
                      disabled={!formik.isValid}
                    >
                      Get OTP
                    </button>
                  )}

                  {errorMessage && (
                    <>
                      <p className="error-message">{errorMessage}</p>
                    </>
                  )}
                  <Link
                    to={`/?client_id=${searchParams.get(
                      "client_id"
                    )}&redirect_uri=${searchParams.get("redirect_uri")}`}
                  >
                    Go back to Sign In
                  </Link>
                </form>
              </>
            )}
            {step === 2 && (
              <>
                <form className="sign-up-form">
                  <img src={mainLogo} alt="Logo" className="logo" />
                  <br />
                  <h3>Verify OTP</h3>
                  <div className="input-field1">
                    <i className="fa fa-lock"></i>
                    <span className="contryCode"></span>
                    <input
                      maxLength={4}
                      type="text"
                      placeholder="OTP"
                      name="confirmCode"
                      value={confirmCode}
                      onBlur={formikOTP.handleBlur}
                      onChange={(e) => {
                        setConfirmCode(e.target.value);
                        formikOTP.setFieldValue("confirmCode", e.target.value);
                      }}
                      onKeyPress={(e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  {formikOTP.touched.confirmCode &&
                  formikOTP.errors.confirmCode ? (
                    <div className="error-message">
                      {formikOTP.errors.confirmCode}
                    </div>
                  ) : null}{" "}
                  {errorMessage && (
                    <p className="error-message">{errorMessage}</p>
                  )}
                  {isLoading === true ? (
                    <div>
                      <center>
                        <p>"Verifying OTP please wait..!"</p>
                        <ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={["#3347c5", "#331492", "#ff0000"]}
                        />
                      </center>
                    </div>
                  ) : (
                    <button
                      onClick={verifyOTP}
                      className={
                        formikOTP.isValid ? "btn solid " : "loginButton"
                      }
                      disabled={!formikOTP.isValid}
                    >
                      Verify OTP
                    </button>
                  )}
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p>Didn't recieve code?</p>
                  )}
                  <button
                    disabled={seconds > 0 || minutes > 0}
                    style={{
                      color:
                        seconds > 0 || minutes > 0
                          ? "#DFE3E8"
                          : "rgb(0, 0, 205)",
                    }}
                    onClick={resendOTP}
                    className="link-button"
                  >
                    Resend OTP
                  </button>
                </form>
              </>
            )}
            {step === 3 && (
              <>
                <form className="sign-up-form">
                  <img src={mainLogo} alt="Logo" className="logo" />
                  <br />
                  <h3>Create Password</h3>
                  <div className="input-field">
                    <i className="fa fa-key" aria-hidden="true"></i>
                    <span className="contryCode"></span>
                    <input
                      placeholder="Enter New Password"
                      type={passwordVisible ? "text" : "password"}
                      // value={formik.values.password}
                      onChange={handlePasswordChange}
                      onBlur={formikSignupPassword.handleBlur}
                      name="password"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      aria-label={
                        passwordVisible ? "Hide password" : "Show password"
                      }
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        height: "100%",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        // padding: "0 1rem",
                        paddingRight: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                      {/* Or use Unicode characters: */}
                      {/* {passwordVisible ? '🙈' : '👁'} */}
                    </button>
                  </div>
                  {formikSignupPassword.touched.password &&
                  formikSignupPassword.errors.password ? (
                    <div className="error-message">
                      {formikSignupPassword.errors.password}
                    </div>
                  ) : null}
                  <div className="input-field">
                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                    <span className="contryCode"></span>
                    <input
                      placeholder="Confirm Password"
                      type={passwordVisible ? "text" : "password"}
                      value={formikSignupPassword.values.confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        formikSignupPassword.setFieldValue(
                          "confirmPassword",
                          e.target.value
                        );
                      }}
                      onBlur={formikSignupPassword.handleBlur}
                      name="confirmPassword"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      aria-label={
                        passwordVisible ? "Hide password" : "Show password"
                      }
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        height: "100%",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        // padding: "0 1rem",
                        paddingRight: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                      {/* Or use Unicode characters: */}
                      {/* {passwordVisible ? '🙈' : '👁'} */}
                    </button>
                  </div>

                  {formikSignupPassword.touched.confirmPassword &&
                  formikSignupPassword.errors.confirmPassword ? (
                    <div className="error-message">
                      {formikSignupPassword.errors.confirmPassword}
                    </div>
                  ) : null}

                  <div>
                    <h3>Password must contain: </h3>
                    {renderValidationMessage(
                      validations.length,
                      "At least 8 characters"
                    )}
                    {renderValidationMessage(
                      validations.lowercase,
                      "Lowercase letters (a-z)"
                    )}
                    {renderValidationMessage(
                      validations.uppercase,
                      "Uppercase letters (A-Z)"
                    )}
                    {renderValidationMessage(
                      validations.number,
                      "Numbers (0-9)"
                    )}
                    {renderValidationMessage(
                      validations.specialChar,
                      "At least 1 special character(e.g. !@#$%^&*)"
                    )}
                  </div>

                  {/* Password match code */}
                  {/* {password.length > 0 && password === confirmPassword ? (
                    <span style={{ color: "green" }}>Password matched ..!</span>
                  ) : null} */}

                  {isLoading === true ? (
                    <div>
                      <center>
                        <p>"Signing up please wait..!"</p>
                        <ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={["#3347c5", "#331492", "#ff0000"]}
                        />
                      </center>
                    </div>
                  ) : (
                    <button
                      onClick={signUp}
                      className={
                        formikSignupPassword.isValid
                          ? "btn solid "
                          : "loginButton"
                      }
                      disabled={!formikSignupPassword.isValid}
                    >
                      Signup
                    </button>
                  )}

                  <center>
                    {errorMessage && (
                      <>
                        {" "}
                        <p className="error-message">{errorMessage}</p>{" "}
                        <Link
                          to={`/?client_id=${searchParams.get(
                            "client_id"
                          )}&redirect_uri=${searchParams.get("redirect_uri")}`}
                        >
                          Go back to Sign In
                        </Link>
                      </>
                    )}
                  </center>
                </form>
              </>
            )}

            {step === 4 && (
              <>
                <div>
                  <center>
                    <img src={SuccessAnim} alt="" />
                    <h2>!.. User Added Successfully ..!</h2>
                    <Link
                      to={`/?client_id=${searchParams.get(
                        "client_id"
                      )}&redirect_uri=${searchParams.get("redirect_uri")}`}
                      onClick={() => clearTimeout(timeoutId)}
                    >
                      Sign In here
                    </Link>
                  </center>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
