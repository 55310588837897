import React from "react";
import Success from "../assets/success.svg";
import Failure from "../assets/failure.svg";

const Screen2 = ({ simSelect, phone, simCount, environment, mobileLoginError }) => {
  return (
    <>
      <div className="stepperContainer">
        <div className="leftpart">
          <img className="success" src={Success} alt="loader" />
          <span className="verticalLine2"></span>
        </div>
        <div className="rightpart">
          <span className="child1">SMS has been triggered!</span>
          {simCount === 1 || environment === "react-native-ios"? (
            <span className="child2">
              An SMS has been triggered!
            </span>
          ) : (
            <span className="child2">
              An SMS has been triggered from <span>{simSelect}</span>
            </span>
          )}
        </div>
      </div>

      <div className="stepperContainer">
        <div className="leftpart">
          <img className="failure" src={Failure} alt="Failure" />
        </div>
        <div className="rightpart">
          <span className="child1">{mobileLoginError}</span>
          {/* <span className="child2">
            We have not received a message from +91{phone}.
          </span> */}
        </div>
      </div>
    </>
  );
};

export default Screen2;
