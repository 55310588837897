const initialState = {
  loggedIn: false,
  user: null,
  isLoading: false,
  error: null,
};

const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState")).auth
  : initialState;

export default function authReducer(
  state = { ...persistedState, error: null },
  action
) {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: true, error: null };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        loggedIn: true,
        user: action.payload.user,
        error: null,
      };
    case "LOGIN_FAILURE":
    case "LOGOUT_FAILURE":
      return { ...state, isLoading: false, error: action.error.message };
    case "LOGOUT":
      return initialState;
    case "LOGIN_STATUS":
      return { ...state };
    default:
      return state;
  }
}
