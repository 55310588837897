import React from "react";
import Success from "../assets/success.svg";

const Screen1 = ({ simSelect, simCount, environment }) => {
  return (
    <div className="stepperContainer">
      <div className="leftpart">
        <img className="success" src={Success} alt="loader" />
      </div>
      <div className="rightpart">
        <span className="child1">SMS has been triggered!</span>
        {simCount === 1 || environment === "react-native-ios" ? (
          <span className="child2">An SMS has been triggered!</span>
        ) : (
          <span className="child2">
            An SMS has been triggered from <span>{simSelect}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default Screen1;
