import {
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { cognitoIdp } from "./UserPool";
import verifyToken, { verifyTokenMobile } from "./verifyToken";
import { checkAccess } from "./accessControl";
import { json } from "react-router-dom";

const cognito = {
  signup: ({ phone, password }) => {
    const UserPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_APPCLIENT_ID,
    });

    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: phone,
      })
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: "name",
        Value: "user",
      })
    );

    return new Promise((resolve, reject) => {
      UserPool.signUp(phone, password, attributeList, null, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  },
  forgotPassword: async (phone, client_id) => {
    const params = {
      ClientId: client_id,
      Username: phone,
    };
    return cognitoIdp.forgotPassword(params, (err, data) => {
      if (err) {
        return { success: false, error: err };
      } else {
        return { success: true, data };
      }
    });
  },
  login: (phone, password, client_id) => {
    const UserPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
      ClientId: client_id,
    });
    const authenticationData = {
      Username: phone,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: phone,
      Pool: UserPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (error) => {
          console.log("ERROR:", error);
          if (error.message.includes("Error::")) {
            let err = { message: error.message.split("Error::")[1] };
            reject(err);
          } else {
            reject(error);
          }
        },
      });
    });
  },

  mobileLogin: async (username, appName) => {
    try {
      const initiateAuthParams = {
        AuthFlow: "CUSTOM_AUTH",
        ClientId: process.env.REACT_APP_COGNITO_APPCLIENT_ID,
        AuthParameters: {
          USERNAME: username,
        },
      };
      const initiateAuthResponse = await cognitoIdp
        .initiateAuth(initiateAuthParams)
        .promise();

      const user = await verifyTokenMobile(
        initiateAuthResponse.AuthenticationResult.IdToken
      );

      // window.ReactNativeWebView.postMessage(
      //   JSON.stringify({ type: "user", user })
      // );

      const access = checkAccess(appName, user);
      if (access) {
        return initiateAuthResponse;
      } else {
        return { error: "This Role is Unauthorized to Access the Client App" };
      }
    } catch (error) {
      console.error("Error initiating custom authentication:", error);
      if (error.message.includes("Error::")) {
        let err = { message: error.message.split("Error::")[1] };
        return { error: err.message };
      } else {
        return { error: error.message };
      }
    }
  },

  logout: async (client_id, token) => {
    const UserPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
      ClientId: client_id,
    });

    const cognitoUser = UserPool.getCurrentUser();

    const params = {
      AccessToken: token,
    };

    cognitoIdp.globalSignOut(params, (err, data) => {
      if (err) {
        console.log("ERROR:", err);
        return { success: false, error: err };
      } else {
        console.log("SUCCESS:", data);
        return { success: true, data };
      }
    });
  },
};

export { cognito };
