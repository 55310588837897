import * as Yup from "yup";

export const loginSchema = Yup.object({
  mobile: Yup.string()
    .required("* Mobile number is required")
    .matches(/^[0-9]{10}$/, " * Mobile number must be 10 digits long")
    .test(
      "is-numeric",
      "* Mobile number can only contain numeric characters",
      (value) => /^\d+$/.test(value)
    ),
});

export const forgotPasswordSchema = Yup.object({
  mobile: Yup.string()
    .required("* Mobile number is required")
    .matches(/^[0-9]{10}$/, " * Mobile number must be 10 digits long")
    .test(
      "is-numeric",
      "* Mobile number can only contain numeric characters",
      (value) => /^\d+$/.test(value)
    ),
});

export const otpSchemaForgotPassword = Yup.object({
  confirmCode: Yup.string()
    .required("* OTP is required")
    .matches(/^[0-9]{4}$/, " * OTP must be 4 digits long")
    .test("is-numeric", "* OTP can only contain numeric characters", (value) =>
      /^\d+$/.test(value)
    ),
  password: Yup.string()
    .required("Password is required.")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      " "
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const signupSchema = Yup.object({
  phone: Yup.string()
    .required("* Mobile number is required")
    .matches(/^[0-9]{10}$/, " * Mobile number must be 10 digits long")
    .test(
      "is-numeric",
      "* Mobile number can only contain numeric characters",
      (value) => /^\d+$/.test(value)
    ),
});

export const signupPasswordSchema = Yup.object({
  password: Yup.string().required("Password is required."),
  confirmPassword: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const otpSchemaSignup = Yup.object({
  confirmCode: Yup.string()
    .required("* OTP is required")
    .matches(/^[0-9]{4}$/, " * OTP must be 4 digits long")
    .test("is-numeric", "* OTP can only contain numeric characters", (value) =>
      /^\d+$/.test(value)
    ),
});
