import { useState } from "react";

const ErrorButton = () => {
  const [errorCount, setErrorCount] = useState(0);

  const handleClick = () => {
    setErrorCount((prevCount) => prevCount + 1);
    triggerError(errorCount);
  };

  const triggerError = (count: any) => {
    const errorMessages = [
      "Error: Something went wrong!",
      "TypeError: This is a type error!",
      "RangeError: This is a range error!",
      "ReferenceError: This is a reference error!",
      "SyntaxError: This is a syntax error!",
      "CustomError: This is a custom error!",
    ];

    const errorTypes = [
      () => {
        throw new Error(errorMessages[0]);
      },
      () => {
        throw new TypeError(errorMessages[1]);
      },
      () => {
        throw new RangeError(errorMessages[2]);
      },
      () => {
        throw new ReferenceError(errorMessages[3]);
      },
      () => {
        throw new SyntaxError(errorMessages[4]);
      },
      () => {
        throw new Error(errorMessages[5]);
      }, // Custom error
    ];

    const errorIndex = count % errorTypes.length;
    errorTypes[errorIndex]();
  };

  return (
    <div>
      <button onClick={handleClick}>Trigger Error</button>
    </div>
  );
};

export default ErrorButton;
