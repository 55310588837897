import { CognitoJwtVerifier } from "aws-jwt-verify";
import {Base64} from 'js-base64';

// Verifier that expects valid access tokens:
const verifierAccessToken = CognitoJwtVerifier.create({
  userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
  tokenUse: "access",
  clientId: process.env.REACT_APP_COGNITO_APPCLIENT_ID,
});
const verifierIdToken = CognitoJwtVerifier.create({
  userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
  tokenUse: "id",
  clientId: process.env.REACT_APP_COGNITO_APPCLIENT_ID,
});
const verifierRefreshToken = CognitoJwtVerifier.create({
  userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
  tokenUse: "refresh",
  clientId: process.env.REACT_APP_COGNITO_APPCLIENT_ID,
});

export default async function verifyToken(token, type) {
  try {
    if (type === "access") {
      const payload = await verifierAccessToken.verify(token);
      return payload;
    } else if (type === "id") {
      const payload = await verifierIdToken.verify(token);
      return payload;
    } else if (type === "refresh") {
      const payload = await verifierRefreshToken.verify(token);
      return payload;
    }
  } catch {
    console.log("Token not valid!");
  }
}

export  async function verifyTokenMobile(idToken) {
  try {
    // Verify the ID token (example)
    const decodedIdToken = JSON.parse(Base64.decode(idToken.split('.')[1]));
    // const verified = new Date() < new Date(decodedIdToken.exp * 1000);
    if (decodedIdToken) {
      console.log('ID token is valid:', decodedIdToken);
      return decodedIdToken;
    } else {
      console.log('ID token is invalid');
      return null;
    }
  } catch (error) {
    console.log('Error verifying token:', error);
    return null;
  }
}
